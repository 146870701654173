









































































































































































































@import '~vuetify/src/styles/settings/_variables.scss';

.va-sidebar {
  position: relative;
  top: initial;
  left: initial;
  height: auto;
  min-height: initial;

  ::v-deep &__menu {
    padding: 0;
  }

  ::v-deep .va-sidebar-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .list.selected {
    display: none;
  }
}
@media #{map-get($display-breakpoints, 'md-and-up')} {
  .goBack {
    display: none;
  }
}
